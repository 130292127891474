import React from "react"
import Cookies from 'universal-cookie'
import axios from 'axios'

const cookies = new Cookies();

class GeoTel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      regionName: '',
      countryCode: '',
    };
  }
  componentDidMount() {
    this.setState({
      regionName: cookies.get('regionName'),
      countryCode: cookies.get('countryCode')
    })
    if (typeof(this.countryCode) == "undefined") {
      axios.get("https://upcable.ru/get_geo/")
        .then(
          (response) => {
            this.setState({
              isLoaded: true,
              regionName: response.data.regionName,
              countryCode: response.data.countryCode
            })
            cookies.set('regionName', response.data.regionName, { path: '/'  })
            cookies.set('countryCode', response.data.countryCode, { path: '/' })
          },
          // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
          // чтобы не перехватывать исключения из ошибок в самих компонентах.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  }

  render() {
    const { type } = this.props
    const { regionName, countryCode } = this.state
    // console.log(cookies.get('regionName'))
    // if (error) {
    //   return <a href="tel:+74951280775">+7 495 128 07 75</a>;
    // }
    // else if (!isLoaded) {
    //   return <a href="tel:+74951280775">+7 495 128 07 75</a>;
    //   // return ''
    // }
    if (regionName === 'Пермский край'){
      if (type === 'header') {
        return <a href="tel:+73422592781">+7 342 259 27 81</a>
      }
      else {
        return (
          <React.Fragment>
            <a href="tel:+78003005655">8 800 300 56 55</a><br/>
            <a href="tel:+73422592781">+7 342 259 27 81</a>
          </React.Fragment>
        );
      }
    } else if (countryCode === 'RU' ) {
      if (type === 'header') {
        return <a href="tel:+78003005655">8 800 300 56 55</a>
      }
      else {
        return (
          <React.Fragment>
            <a href="tel:+78003005655">8 800 300 56 55</a><br/>
            <a href="tel:+74951280775">+7 495 128 07 75</a>
          </React.Fragment>
        );
      }
    } else if (countryCode === 'KZ' ){
      return (
        <a href="tel:+77172695902">+7 717 269 59 02</a>
      );
    } else {
      if (type === 'header') {
        return <a href="tel:+78003005655">8 800 300 56 55</a>
      } else {
        return (
          <React.Fragment>
            <a href="tel:+78003005655">8 800 300 56 55</a><br/>
            <a href="tel:+74951280775">+7 495 128 07 75</a>
          </React.Fragment>
        );
      }
    }
  }
}

export default GeoTel