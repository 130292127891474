import React from "react"
import PropTypes from "prop-types"
import logo from "../images/upkLogo.png";
import { Link } from "gatsby"
// import SearchBar from "../templates/All/SearchBar"
import GeoTel from "../templates/All/GeoTel"

import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { ListItemText } from "@material-ui/core"

// import './ie11fix.css'

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      fontSize: "16px",
    },
    body: {
      fontFamily:'OpenSans,Helvetica Neue,Helvetica,Arial,sans-serif;',
      fontKerning: 'normal',
      fontSize: "0.95rem",
      lineHeight: "1.5",
      backgroundColor: "#FFF",
      textRendering: 'optimizeLegibility',
      fontFeatureSettings: "'kern', 'liga'",
      // fontSmoothing: "antialiased"
      minWidth: '360px'
    },
    h1: {
      fontSize: '1.7em'
    },
    h2: {
      fontSize: '1.3em'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      // display: 'table',
      // borderColor: 'grey',
      // borderSpacing: '2px'
    },
    thead: {
      backgroundColor: '#f5f5f5',
      border: '1px solid #e2e9ea'
    },
    th: {
      textAlign: 'center',
      padding: '3px 5px 3px 5px',
      border: '1px solid #e2e9ea'
    },
    td: {
      textAlign: 'center',
      padding: '3px 5px 3px 5px',
      border: '1px solid #e2e9ea'
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    a: {
      color: '#57777d',
      textDecoration: 'none',
      '&:hover': {
        color: '#7794be'
      }
    }
  },
  header: {
    paddingTop: theme.spacing(2),
    fontWeight: '500',
    fontSize: '0.825rem'
  },
  logo: {
    width: "256px"
  },
  navlinks: {
    flexDirection: 'row',
    display: 'flex',
    textTransform: 'uppercase',
    letterSpacing: '.1px'
  },
  navlinks_item: {
    marginRight: '30px!important'
  },
  navcontact:{
    fontSize: '1.2em',
    '& ul': {
      textAlign: 'right'
    },
    '& a': {
      '&:hover': {
        color: '#000'
      },
      '&:link': {
        color: '#333'
      },
      '&:visited': {
        color: '#333'
      }
    }
  },
  header_tel:{
    fontWeight: '700',
    fontSize: '1.2em'
  },
  header_mail:{
    fontWeight: '500',
    fontSize: '.9em'
  },
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%'
  },
  main: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  footer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: '.825rem',
    backgroundColor: "#262626",
    marginTop: 'auto',
    color: "#f5f5f5",
    '& a': {
      '&:hover': {
        color: '#f5f5f5'
      },
      '&:link': {
        color: '#f5f5f5'
      },
      '&:visited': {
        color: '#f5f5f5'
      }
    },
    '& hr': {
      backgroundColor: '#f5f5f5',
      height: '1px',
      margin: theme.spacing(1, 0, 1)
    },

    // display:'inline-block'
  },
  navfooter: {
    // flexDirection: 'row',
    // display: 'flex',
    textTransform: 'uppercase',
    letterSpacing: '.1px',
    paddingBottom: theme.spacing(2)
  },
  navfooter_item: {
    marginRight: '4%',
    display: 'inline',
    fontWeight: '700'
  },
  footer_tel:{
    fontWeight: '700',
    fontSize: '1.2em',
  },
  footer_mail:{
    fontWeight: '500',
    fontSize: '.9em'
  },
  drawer: {
    width: "75vw",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "75vw",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
  },
  drawerFooter: {
    // display: 'flex',
    // alignItems: 'center',
    padding: theme.spacing(2, 2),
    // justifyContent: 'flex-end',
    fontWeight: '700',
    fontSize: '1.2em',
    color: "#333",
    '& a': {
      '&:hover': {
        color: '#333'
      },
      '&:link': {
        color: '#333'
      },
      '&:visited': {
        color: '#333'
      }
    },
  }
}))

const Layout = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true);
  }
  const handleDrawerClose = () => {
    setOpen(false);
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="header" maxWidth={'lg'} className={classes.header}>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <Grid item><Link to={`/`}><img src={logo} className={classes.logo} alt="Официальный сайт компании Уралпромкабель" /></Link></Grid>
          <Hidden smDown implementation={'css'}>
            <Grid item>
              <nav>
                <ul className={classes.navlinks} aria-label="Разделы сайта">
                  <li className={classes.navlinks_item}><Link to={`/catalog/`}>Продукция</Link></li>
                  <li className={classes.navlinks_item}><Link to={`/delivery/`}>Доставка</Link></li>
                  <li className={classes.navlinks_item}><Link to={`/guides/`}>Справочник</Link></li>
                  <li className={classes.navlinks_item}><Link to={`/contacts/`}>Контакты</Link></li>
                </ul>
              </nav>
            </Grid>
          </Hidden>
          <Grid item className={classes.navcontact}>
            <Hidden smDown implementation={'css'}>
              <ul>
                <li className={classes.header_tel}><GeoTel type={'header'} /></li>
                <li className={classes.header_mail}><a href="mailto:mail@upcable.ru">mail@upcable.ru</a></li>
              </ul>
            </Hidden>
            <Hidden mdUp implementation={'css'}>
              <IconButton onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Grid>
          {/*<Grid item xs="12">*/}
          {/*  <SearchBar/>*/}
          {/*</Grid>*/}
        </Grid>
        <Drawer
          open={open}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <ClickAwayListener onClickAway={handleDrawerClose}>
          <div>
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <List>
              <Link to={`/catalog/`}><ListItem button><ListItemText primary="Продукция" /></ListItem></Link>
              <Link to={`/delivery/`}><ListItem button><ListItemText primary="Доставка" /></ListItem></Link>
              <Link to={`/guides/`}><ListItem button><ListItemText primary="Справочник" /></ListItem></Link>
              <Link to={`/contacts/`}><ListItem button><ListItemText primary="Контакты" /></ListItem></Link>
            </List>
            <Divider light="true"/>
            <div className={classes.drawerFooter}>
              <GeoTel /><br/>
              <a href="mailto:mail@upcable.ru">mail@upcable.ru</a>
            </div>
          </div>
          </ClickAwayListener>
        </Drawer>
      </Container>
        <Container maxWidth={'lg'} alignItems="center" className={classes.main}>
          {children}
        </Container>
      <footer className={classes.footer}>
        <Container maxWidth={'lg'} alignItems="center">
            <Hidden xsDown implementation={'css'}>
              <Grid container>
                <Grid item sm={'9'} xs={'12'}>
                  <nav>
                    <ul className={classes.navfooter} aria-label="Разделы сайта">
                      <li className={classes.navfooter_item}><Link to={`/catalog/`}>Продукция</Link></li>
                      <li className={classes.navfooter_item}><Link to={`/delivery/`}>Доставка</Link></li>
                      <li className={classes.navfooter_item}><Link to={`/guides/`}>Справочник</Link></li>
                      <li className={classes.navfooter_item}><Link to={`/contacts/`}>Контакты</Link></li>
                    </ul>
                  </nav>
                  © 2012—{new Date().getFullYear()} ООО "Уралпромкабель"
                </Grid>
                <Grid item sm={'3'} xs={'12'} className={classes.footer_tel}>
                  <GeoTel /><br/>
                  <a href="mailto:mail@upcable.ru">mail@upcable.ru</a>
                </Grid>
              </Grid>
            </Hidden>
            <Grid container>
            <Hidden smUp implementation={'css'}>
              <Grid item xs={'12'} className={classes.footer_tel}>
                <GeoTel /><br/>
                <a href="mailto:mail@upcable.ru">mail@upcable.ru</a>
                <Divider light="true" className={classes.footer_divider} />
              </Grid>
              <Grid item xs={'12'}>
                © 2012—{new Date().getFullYear()} ООО "Уралпромкабель"
              </Grid>
            </Hidden>
            </Grid>
        </Container>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
